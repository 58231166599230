import * as React from "react";
import { Fragment } from "react";
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  DateField,
  DatagridBody,
  ShowButton,
  ImageField,
  EmailField,
} from "react-admin";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import ApproveViewsButton from "./approve.view.buttons";
import ApproveButton from "./approve.user";
import UserFilters from "./user.filters";

const UserBulkActionButtons = (props) => {
  return (
    <Fragment>
      <ApproveViewsButton {...props} />
    </Fragment>
  );
};

const UserDatagridRow = ({
  record,
  resource,
  id,
  onToggleItem,
  children,
  basePath,
  className,
}) => {
  return (
    <TableRow key={id} className={className}>
      {/* first column: selection checkbox */}
      <TableCell padding="none">
        {record.status !== "pending" && (
          <Checkbox
            disabled={
              (record.status === "APPROVED" &&
                record.role.roleName === "CREATOR") ||
              record.role.roleName === "COLLECTOR"
            }
            onClick={(event) => onToggleItem(record, event)}
          />
        )}
      </TableCell>
      {/* data columns based on children */}
      {React.Children.map(children, (field) => (
        <TableCell key={`${id}-${field.props.source}`}>
          {React.cloneElement(field, {
            record,
            basePath,
            resource,
          })}
        </TableCell>
      ))}
    </TableRow>
  );
};

const UserDatagridBody = (props) => (
  <DatagridBody {...props} row={<UserDatagridRow />} />
);
const UserDatagrid = (props) => (
  <Datagrid {...props} body={<UserDatagridBody />} />
);

const UserShowButton = ({ record }) => (
  <ShowButton basePath="/users" label="Show" record={record} />
);

const useStyles = makeStyles({
  imgContainer: {
    "& img": {
      height: "50px",
      width: "50px",
      objectFit: "contain",
      borderRadius: "50%",
    },
  },
});

const UserPagination = (props) => (
  <Pagination rowsPerPageOptions={[]} {...props} />
);

const Users = (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      title="List of Users"
      perPage={20}
      pagination={<UserPagination />}
      //   filters={<UserFilters />}
      bulkActionButtons={<UserBulkActionButtons {...props} />}
    >
      <UserDatagrid>
        {/* <TextField source="id" sortable={false}  /> */}
        <ImageField
          source="profile"
          sortable={false}
          label="Profile"
          className={classes.imgContainer}
        />
        <TextField source="name" sortable={false} />
        {/* <BooleanField source="isActive" sortable={false}  /> */}
        {/* <BooleanField source="acceptedByAdmin" sortable={false}  /> */}
        <EmailField source="email" sortable={false} />
        <TextField source="status" sortable={false} />
        <TextField source="role.roleName" label="Role" sortable={false} />
        <TextField source="walletAddress" sortable={false} />
        <DateField source="createdAt" sortable={false} />
        <UserShowButton source="View" />
        {/* <ApproveButton source="Action" /> */}
      </UserDatagrid>
    </List>
  );
};

export default Users;
