import React, { Fragment } from 'react';
import { Button, SimpleShowLayout, SimpleForm, NumberInput, useRedirect, useNotify, TextField, ImageField } from 'react-admin'
import { useState, useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { services } from "../../services";
import Loader from "../loading";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    imgContainer: {
        '& img': {
            height: '150px',
            width: '300px',
            objectFit: 'contain',
        }
    }
})


const PopularCollectionCreateButton = ({ record }) => {

    const classes = useStyles();

    const notify = useNotify()
    const redirect = useRedirect()

    const [showDialog, setShowDialog] = useState(false);
    const [params, setParams] = useState(null);
    const [loading, setLoading] = useState(false);
    const [callAPI, setCallAPI] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    }

    const handleCloseClick = () => {
        setShowDialog(false);
    }

    useEffect(() => {
        if (callAPI) {
            addPopularCollection()
        }
    }, [callAPI])

    const addPopularCollection = async () => {
        const request = services.post(`/admin/popularCollection/add`, params);
        request.then((response) => {
            if (response.status < 200 || response.status >= 300) {
                setLoading(false) // stop loading.
                setShowDialog(false);
                throw new Error(response.statusText);
            }
            if (response.status === 200) {
                notify(response.data.message, `success`)
                setLoading(false) // stop loading.
                setShowDialog(false);
            }
        })
            .catch((e) => {
                setLoading(false) // stop loading.
                setShowDialog(false);
                if (e.response) {
                    if (e.response.status === 401 || e.response.status === 403) {
                        notify(`Unauthorized Request`, `error`)
                        localStorage.removeItem('token');
                        redirect('/collections')
                    }
                    // other error code (404, 500, etc): no need to log out
                } else {
                    notify(`Bad request`, `error`)
                    redirect('/collections')
                }
            });
    }

    const onSave = async (event) => {
        setLoading(true) // start loader
        let params = {
            ranking: event.ranking,
            collectionId: record.id
        }
        setParams(params);
        setCallAPI(true);
    }

    return (
        loading ? <Loader /> :
            <div>
                <Fragment>
                    <Button onClick={handleClick} label="Popular">
                    </Button>

                    <Dialog
                        fullWidth
                        open={showDialog}
                        onClose={handleCloseClick}
                        aria-label="popular Collection"
                    >
                        <DialogTitle>Popular Collection</DialogTitle>
                        <DialogContent>

                            <SimpleShowLayout>
                                <TextField source="id" sortable={false} />
                                <TextField source="name" sortable={false} />
                                <ImageField source="logo" sortable={false} className={classes.imgContainer} />
                            </SimpleShowLayout>

                            <SimpleForm
                                save={onSave}
                            // form="post-quick-create"
                            // resource="posts"
                            // onSubmit={this.handleSubmit}
                            // toolbar={null}
                            >
                                <SimpleShowLayout>
                                    <NumberInput source="ranking" required initialValue={0} />
                                </SimpleShowLayout>
                            </SimpleForm>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                label="Cancel"
                                onClick={handleCloseClick}
                            >
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Fragment>
            </div>
    );
}

export default PopularCollectionCreateButton;