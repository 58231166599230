import * as React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";

import { SimpleForm, BooleanInput, useNotify } from "react-admin";
import { useState, useEffect } from "react";
import { actions } from "../../actions";

const Dashoard = (props) => {
  const notify = useNotify();
  const [state, setState] = useState();
  const [params, setParams] = useState(false);

  useEffect(() => {
    async function getSwitches() {
      const tabs = await actions.getSwitches();
      setState({ tabs: tabs });
    }
    getSwitches();

    async function updateSwitches() {
      const response = await actions.updateSwitches(params.id, {
        isActive: params.isActive,
      });
      notify(response.message, `success`);
      setState((prevState) => ({
        ...prevState,
        tabs: prevState.tabs.map((tab) => ({
          ...tab,
          isActive: tab.id === params.id ? params.isActive : tab.isActive,
        })),
      }));
    }

    if (params) {
      updateSwitches();
    }
  }, [params]);

  const onChange = (isActive, id) => {
    setParams({ id: id, isActive: isActive });
  };

  return (
    <Card>
      <CardHeader title="Welcome to the administration" />
      <CardContent>This is the NFT Marketplace admin panel...</CardContent>
      <br />
      <SimpleForm toolbar={null}>
        {state
          ? state.tabs.map((tab, index) => {
              return (
                <BooleanInput
                  key={index}
                  label={tab.isActive ? `${tab.name}` : `Enable ${tab.name}`}
                  id={tab.id}
                  name={tab.id}
                  defaultValue={tab.isActive ? true : false}
                  source="isActive"
                  onChange={(event) => onChange(event, tab.id)}
                />
              );
            })
          : ""}
      </SimpleForm>
    </Card>
  );
};

export default Dashoard;
