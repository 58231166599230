import * as React from "react";
import {
    Button,
    useRefresh,
    useNotify,
    useUnselectAll,
    useRedirect
} from 'react-admin';
import UserIcon from '@material-ui/icons/Group';
import { services } from "../../services";
import { actions } from "../../actions";
import { useState, useEffect } from 'react';
import Loader from "../loading";



const ApproveViewsButton = (record) => {

    const [transactionId, setTransactionId] = useState(null);
    const [status, setStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiCall, setAPICalled] = useState(false);
    const [web3Data, setWeb3Data] = useState(null);
    const [ownerAdd, setOwnerAdd] = useState(null);
    const [nftContractInstance, setNftContractInstance] = useState(null);

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const unselectAll = useUnselectAll();

    useEffect(() => {

        async function getNftContractInstance() {
            const nftContractInstance = await actions.getNFTContractInstance();
            setNftContractInstance(nftContractInstance);
            const adminAddress = await nftContractInstance.methods.admin().call();
            setOwnerAdd(adminAddress);
        }
        getNftContractInstance();

        async function getWeb3Data() {
            const web3Data = await services.getWeb3();
            setWeb3Data(web3Data);
        }
        getWeb3Data();


        async function approveCreator() {
            const users = []
            record.selectedIds.map((user, index) => {
                return users.push({ 'id': user.id, 'status': status })
            })
            const params = { user: users, 'transactionId': transactionId }
            const request = services.post('/user/aprrove', params);
            request.then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    setLoading(false) // stop loading.
                    throw new Error(response.statusText);
                }
                if (response.status === 200) {
                    console.log(response.data, response.data.message)
                    setLoading(false) // stop loading.
                    unselectAll(record.resource);
                    notify(response.data.message, `success`)
                    refresh();
                }
            })
                .catch((e) => {
                    setLoading(false) // stop loading.
                    if (e.response) {
                        if (e.response.status === 401 || e.response.status === 403) {
                            notify(`Unauthorized Request`, `error`)
                            localStorage.removeItem('token');
                            refresh()
                        }
                        // other error code (404, 500, etc): no need to log out
                    } else {
                        notify(`Bad request`, `error`)
                        refresh()
                    }
                });
        }

        if (apiCall) {
            approveCreator()
        }
    }, [transactionId, apiCall]);

    const onTransaction = async (walletAddresses) => {
        if (web3Data.accounts[0] === ownerAdd) {
            await nftContractInstance.methods
                .approveCreators(walletAddresses)
                .send({ from: web3Data.accounts[0] })
                .on("transactionHash", (hash) => {
                    console.log('transaction hash : ', hash);
                    setTransactionId(hash)
                    setLoading(true) // start loading. 
                })
                .on("receipt", (receipt) => {
                    console.log('on receipt ', receipt)
                    setStatus(true)
                    setAPICalled(true)
                })
                .on("error", (error) => {
                    setAPICalled(true)
                    console.log('on error ', error) // error.code===401 user reject the metamask transaction
                });
        } else {
            notify(`User is not an owner of the contract`, `warning`)
            redirect('/users');
            return false
        }
    }

    const updateMany = () => {
        const walletAddresses = []
        record.selectedIds.map((user, index) => {
            walletAddresses.push(user.walletAddress)
        })
        if (walletAddresses) {
            onTransaction(walletAddresses) // metamask transaction approval
        } else {
            notify(`Select one user first`, `warning`)
        }
    }

    return (
        loading ? <Loader /> :
            <Button
                icon={UserIcon}
                label="Approve"
                onClick={updateMany}
            >
            </Button>
    );
};

export default ApproveViewsButton;