import { useState, useEffect } from "react";
import fileToArrayBuffer from "file-to-array-buffer";
import { storeNFT } from "../../config/storage";
import {
  SimpleForm,
  TextInput,
  useRedirect,
  useNotify,
  SelectInput,
  NumberInput,
  required,
  ImageInput,
  ImageField,
  SelectArrayInput,
  SimpleShowLayout,
  FileInput,
  FileField,
} from "react-admin";
import { actions } from "../../actions";
import { services } from "../../services";
import Loader from "../loading";
import { web3 } from "../../web3";
import { compressImage } from "../../helper/function";
import ipfs from "../../config/ipfs";

const NFTCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showPercentage, setShowPercentage] = useState(false);
  const [categories, setCategories] = useState([]);
  const [creators, setCreators] = useState([]);
  const [co_creators, setCoCreators] = useState([]);
  const [collections, setCollections] = useState([]);
  const [data, setData] = useState({ unlockContent: false, platformFees: 0 });
  const [token, setToken] = useState(null);
  const [callAPI, setCallAPI] = useState(false);
  const [nftData, setNFTData] = useState();
  const [buffer, setBuffer] = useState({ original: null, compressed: null });
  const [size, setSize] = useState({ original: 0, compressed: 0 });
  const [web3Data, setWeb3Data] = useState(null);
  const [uploadType, setUploadType] = useState(null);
  const [nftContractInstance, setNftContractInstance] = useState(null);

  const saleState = [
    { name: "AUCTION", value: "AUCTION" },
    { name: "BUY NOW", value: "BUY" },
  ];

  useEffect(() => {
    async function getNftContractInstance() {
      const nftContractInstance = await actions.getNFTContractInstance();
      setNftContractInstance(nftContractInstance);
    }
    getNftContractInstance();

    async function getWeb3Data() {
      const web3Data = await services.getWeb3();
      setWeb3Data(web3Data);
    }
    getWeb3Data();

    async function getCateogries() {
      const categories = await actions.getCategories();
      setCategories(categories.data);
    }
    getCateogries();

    async function getCreators() {
      const roles = await actions.getRoles();
      const roleId = roles
        .filter((role) => role.roleName === "CREATOR")
        .map((role) => role.id)[0];
      const creators = await actions.getAllUsersByRoleId(roleId);
      setCreators(creators);
      setCoCreators(creators);
    }
    getCreators();
  }, []);

  useEffect(() => {
    if (token) {
      mintingNFT();
      setToken(null);
    }
  }, [token]);

  useEffect(() => {
    if (callAPI) {
      createNFT();
      setCallAPI(false);
    }
  }, [callAPI]);

  const getUserCollections = async (creator) => {
    const collection = await actions.getUserCollections(creator);
    setCollections(collection.data);
    let co_creators = creators
      .filter((data) => data.id !== creator)
      .map((user) => user);
    setCoCreators(co_creators);
  };

  const mintingNFT = async () => {
    await nftContractInstance.methods
      .mintToken(
        data.edition,
        token,
        getAddress(data.ownerId, creators),
        data.coCreator
          ? getAddress(data.coCreator.userId, creators)
          : "0x0000000000000000000000000000000000000000",
        data.coCreator ? 100 - data.coCreator.percentage : "100",
        data.coCreator ? data.coCreator.percentage : "0",
        data.saleState === "AUCTION" ? "1" : "0",
        data.auctionTime ? data.auctionTime : Number(0),
        web3.utils.toWei(data.price.toString(), "ether"),
        data.platformFees ? data.platformFees : "0",
        "0x0000000000000000000000000000000000000000"
      )
      .send({ from: web3Data.accounts[0] })
      .on("transactionHash", (hash) => {
        console.log("transaction hash : ", hash);
        // setTransactionId(hash)
        console.log(hash);
      })
      .on("receipt", (receipt) => {
        console.log("on receipt ", receipt);
        onReciept(receipt);
      })
      .on("error", (error) => {
        console.log("on error ", error); // error.code===4001 user reject the metamask transaction
        onTransactionError(error);
      });
  };

  const onReciept = (receipt) => {
    setLoading(false); // stop loading
    notify("NFT added successfully", `success`);
    redirect("/nft");
  };

  const onTransactionError = (error) => {
    setLoading(false); // stop loading
    notify("NFT can not be added successfully", `error`);
  };

  const createNFT = async () => {
    const request = services.post(`/nft/addNft`, data);
    request
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          setLoading(false); // stop loading.
          throw new Error(response.statusText);
        }
        if (response.status === 200) {
          console.log(response.data);
          setToken(response.data.data._id);
        }
      })
      .catch((e) => {
        setLoading(false); // stop loading.
        if (e.response) {
          if (e.response.status === 401 || e.response.status === 403) {
            notify(`Unauthorized Request`, `error`);
            localStorage.removeItem("token");
            redirect("/nft");
          }
          // other error code (404, 500, etc): no need to log out
        } else {
          notify(`Bad request`, `error`);
          redirect("/nft");
        }
      });
  };

  const onSuccess = async (params) => {
    console.log("buffer", buffer);
    setLoading(true); // start loader
    const image = data.image;
    let original_hash,
      compressed_hash = null;
    // if (image.size > 3145728) {
    original_hash = await storeNFT(buffer, buffer.name, "desc");
    compressed_hash = await storeNFT(buffer, buffer.name, "desc");
    // } else {
    //   original_hash = await storeNFT(buffer, params.name.en, "desc");
    //   compressed_hash = original_hash;
    // }
    setData({
      ...data,
      image: {
        original: original_hash.path,
        compressed: compressed_hash.path,
        format: uploadType,
      },
    });
    setCallAPI(true);
  };

  const getAddress = (id, creators) => {
    const address = creators
      .filter((data) => data.id === id)
      .map((user) => user.walletAddress)[0];
    return address;
  };

  return loading ? (
    <Loader />
  ) : (
    <SimpleForm save={onSuccess}>
      <h4>Item Description</h4>
      <SimpleShowLayout>
        <SelectInput
          source="fileType"
          choices={[
            { name: "Image", id: "image" },
            { name: "Audio", id: "audio" },
            { name: "Video", id: "video" },
          ]}
          optionValue="id"
          optionText={(type) => `${type.name}`}
          onChange={(event) => {
            setUploadType(event.target.value);
          }}
        />

        {uploadType === `image` ? (
          <ImageInput
            source="image"
            label="NFT Image"
            placeholder="Upload the nft image."
            accept="image/*"
            multiline={false}
            validate={required()}
            onChange={async (file) => {
              console.log("file", file);
              // if (file.size > 3145728) {
              setBuffer(file);

              // let compFile = await compressImage(file);
              // let compBuffer = await fileToArrayBuffer(compFile).then(
              //   (comp) => {
              //     return comp;
              //   }
              // );
              // let originBuffer = await fileToArrayBuffer(file).then(
              //   (origin) => {
              //     return origin;
              //   }
              // );
              // setBuffer({
              //   ...buffer,
              //   original: originBuffer,
              //   compressed: compBuffer,
              // });
              // setSize({
              //   ...size,
              //   original: file.size,
              //   compressed: compFile.size,
              // });
              // } else {
              //   setBuffer(file);
              // }
            }}
          >
            <ImageField source="src" title="NFT image" />
          </ImageInput>
        ) : (
          ``
        )}

        {uploadType === `video` ? (
          <FileInput
            source="video"
            label="NFT Video"
            accept="video/*"
            validate={required()}
            placeholder="Upload the nft video."
            onChange={async (file) => {
              fileToArrayBuffer(file).then((data) => {
                setBuffer({ ...buffer, original: data, compressed: data });
              });
              setSize({ ...size, original: file.size, compressed: file.size });
              setData({ ...data, image: file });
            }}
          >
            <FileField source="src" title="NFT Video" />
          </FileInput>
        ) : (
          ``
        )}

        {uploadType === `audio` ? (
          <FileInput
            source="audio"
            label="NFT Audio"
            accept="audio/*"
            validate={required()}
            placeholder="Upload the nft audio."
            onChange={async (file) => {
              fileToArrayBuffer(file).then((data) => {
                setBuffer({ ...buffer, original: data, compressed: data });
              });
              setSize({ ...size, original: file.size, compressed: file.size });
              setData({ ...data, image: file });
            }}
          >
            <FileField source="src" title="NFT Audio" />
          </FileInput>
        ) : (
          ``
        )}

        <TextInput
          source="title"
          validate={required()}
          onChange={(event) => setData({ ...data, title: event.target.value })}
        />
        <TextInput
          source="description"
          validate={required()}
          onChange={(event) =>
            setData({ ...data, description: event.target.value })
          }
        />
      </SimpleShowLayout>

      <h4>Select Creator</h4>
      <SimpleShowLayout>
        <SelectInput
          source="ownerId"
          choices={creators}
          optionValue="id"
          optionText={(creator) => `${creator.name} - ${creator.email}`}
          onChange={(event) => {
            setData({ ...data, ownerId: event.target.value });
            getUserCollections(event.target.value);
          }}
        />
      </SimpleShowLayout>

      <h4>Co-Creator</h4>
      <SimpleShowLayout>
        <SelectInput
          source="userId"
          choices={co_creators}
          optionValue="id"
          optionText={(creator) => `${creator.name} - ${creator.walletAddress}`}
          onChange={(event) => {
            setData({ ...data, coCreator: { userId: event.target.value } });
            setShowPercentage(true);
          }}
        />
        {showPercentage ? (
          <NumberInput
            source="percentage"
            validate={required()}
            onChange={(event) => {
              setData({
                ...data,
                coCreator: {
                  ...data.coCreator,
                  percentage: event.target.value,
                },
              });
            }}
          />
        ) : (
          ""
        )}
      </SimpleShowLayout>

      <h4>Category & Collection</h4>
      <SimpleShowLayout>
        <SelectArrayInput
          source="category"
          choices={categories}
          optionValue="id"
          optionText={(category) => category.categoryName.en}
          onChange={(event) => {
            setData({ ...data, category: event.target.value });
          }}
          validate={required()}
        />
        <SelectInput
          source="collectionId"
          choices={collections}
          optionValue="id"
          optionText={(collection) => `${collection.name}`}
          onChange={(event) => {
            setData({ ...data, collectionId: event.target.value });
          }}
        />
      </SimpleShowLayout>

      <h4>Marketplace Settings</h4>
      <SimpleShowLayout>
        <SelectInput
          source="saleState"
          choices={saleState}
          optionValue="value"
          optionText={(sale) => `${sale.name}`}
          onChange={(event) => {
            setData({ ...data, saleState: event.target.value });
            if (event.target.value === "AUCTION") {
              setShowTime(true);
            } else {
              setShowTime(false);
            }
          }}
          validate={required()}
        />

        {showTime ? (
          <NumberInput
            source="auctionTime"
            validate={required()}
            max={504}
            onChange={(event) => {
              setData({ ...data, auctionTime: event.target.value });
            }}
          />
        ) : (
          ""
        )}

        <NumberInput
          source="edition"
          validate={required()}
          onChange={(event) => {
            setData({ ...data, edition: event.target.value });
            setNFTData({ ...nftData, _editions: event.target.value });
          }}
        />
        <NumberInput
          source="price"
          validate={required()}
          onChange={(event) => {
            setData({ ...data, price: event.target.value });
            setNFTData({ ...nftData, _pricePerNFT: event.target.value });
          }}
        />
      </SimpleShowLayout>

      <h4>Unlockable Content</h4>
      <SimpleShowLayout>
        <TextInput
          source="digitalKey"
          onChange={(event) => {
            if (event.target.value) {
              setData({
                ...data,
                digitalKey: event.target.value,
                unlockContent: true,
              });
            } else {
              setData({ ...data, unlockContent: false });
            }
          }}
        />
      </SimpleShowLayout>

      <h4>Platform Fee</h4>
      <SimpleShowLayout>
        <NumberInput
          source="platform fee"
          initialValue={data.platformFees}
          max={50}
          onChange={(event) => {
            setData({ ...data, platformFees: event.target.value });
          }}
        />
      </SimpleShowLayout>
    </SimpleForm>
  );
};

export default NFTCreate;
