import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  TopToolbar,
  EditButton,
  BooleanField,
  ImageField,
  ArrayField,
  Datagrid,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import BackButton from '../backButton';

const useStyles = makeStyles({
  imgContainer: {
    '& img': {
      height: '150px',
      width: '150px',
      objectFit: 'contain',
    },
  },
});

const CollectionShow = (props) => {
  const classes = useStyles();

  const CategoryShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
      <BackButton
        // variant='outlined'
        color='primary'
        style={{ marginLeft: '5rem' }}
      />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );

  return (
    <Show actions={<CategoryShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField source='id' sortable={false} />
        <ImageField
          source='logo'
          sortable={false}
          className={classes.imgContainer}
        />
        <TextField source='name' sortable={false} />
        <DateField source='description' sortable={false} />
        <ArrayField source='category'>
          <Datagrid>
            <TextField source='id' label='Id' sortable={false} />
            <TextField source='categoryName.en' label='Name' sortable={false} />
          </Datagrid>
        </ArrayField>
        {/* <BooleanField source="isActive" sortable={false}  /> */}
      </SimpleShowLayout>
    </Show>
  );
};

export default CollectionShow;
