import axios from "axios";

// production server
export const api = axios.create({
  baseURL: "https://apinftm.minddeft.com/api/v1/",
});

export const apiUrl = "https://apinftm.minddeft.com/api/v1/";

// aws s3 bucket confiurations
export const awsRegion = "eu-central-1";
export const awsIdentityPoolId =
  "eu-central-1:949b9487-ed35-4eb3-a2d3-1866e6ad890b";
export const awsBucket = "avangrat-development";
