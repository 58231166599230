import { services } from "../../services";
let networkId = 1;
async function fetchNetworkId() {
  networkId = await services.getNetworkId();
  console.log("Network Id", networkId);
}
fetchNetworkId();

function getContractAddresses() {
  if (networkId === "0x4" || +networkId === 4)
    return {
      nftContractAddress: "0xF26a6030C7F63026cdA3dbE8B04DAb0E93AB0FF3",
    };
  else if (+networkId === 1 || networkId === "0x1")
    return {
      nftContractAddress: "0xF26a6030C7F63026cdA3dbE8B04DAb0E93AB0FF3",
    };
  else
    return {
      nftContractAddress: "0xF26a6030C7F63026cdA3dbE8B04DAb0E93AB0FF3",
    };
}
export default getContractAddresses;
