import { useEffect, useState } from "react";
import {
  SimpleForm,
  TextInput,
  useRedirect,
  useNotify,
  ImageInput,
  ImageField,
  required,
} from "react-admin";
import fileToArrayBuffer from "file-to-array-buffer";
import { services } from "../../services";
import ipfs from "../../config/ipfs";
import Loader from "../loading";
import { storeNFT } from "../../config/storage";

const ProfileInfoCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  const [buffer, setBuffer] = useState(null);
  const [mobBuffer, setMobBuffer] = useState(null);
  const [params, setParams] = useState({ url: null, banner: null });

  useEffect(() => {
    async function addProfileInfo() {
      const request = services.post(`/admin/profile-info/add`, params);
      request
        .then((response) => {
          setLoading(false); // stop loading.
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          if (response.status === 200) {
            notify(response.data.message, `success`);
            redirect("/profile-info");
          }
        })
        .catch((e) => {
          setLoading(false); // stop loading.
          if (e.response) {
            if (e.response.status === 401 || e.response.status === 403) {
              notify(`Unauthorized Request`, `error`);
              localStorage.removeItem("token");
              redirect("/profile-info");
            }
            // other error code (404, 500, etc): no need to log out
          } else {
            notify(`Bad request`, `error`);
            redirect("/profile-info");
          }
        });
    }

    if (callAPI) {
      addProfileInfo();
    }
  }, [callAPI]);

  const onSuccess = async (event) => {
    setLoading(true); // start loader
    let { banner, mobile } = event;
    let banner_ = { en: null, tu: null };
    let mobile_ = { en: null, tu: null };

    // banner_.en = await ipfs.add(buffer.en, {
    //   // get buffer IPFS hash
    //   pin: true,
    //   progress: (bytes) => {
    //     // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (banner.en.rawFile.size)))
    //   },
    // });
    banner_.en = await storeNFT(buffer?.en, buffer?.en?.name, "desc");
    banner_.en = banner_.en.path;

    banner_.tu = await storeNFT(buffer?.tu, buffer?.tu?.name, "desc");
    banner_.tu = banner_.tu.path;

    mobile_.en = await storeNFT(mobBuffer?.en, mobBuffer?.en?.name, "desc");
    mobile_.en = mobile_.en.path;

    mobile_.tu = await storeNFT(mobBuffer?.tu, mobBuffer?.tu?.name, "desc");
    mobile_.tu = mobile_.tu.path;

    setParams({
      url: event.url,
      banner: banner_,
      mobile: mobile_,
    });
    setCallAPI(true);
  };

  return loading ? (
    <Loader />
  ) : (
    <SimpleForm save={onSuccess}>
      <ImageInput
        source="banner.en"
        label="Add Desktop Image For English"
        placeholder="Upload the image."
        accept="image/*"
        multiline={false}
        validate={required()}
        onChange={async (file) => {
          setBuffer({ ...buffer, en: file });
          // fileToArrayBuffer(file).then((data) => {
          //     setBuffer({ ...buffer, en: data })
          // })
        }}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="banner.tu"
        label="Add Desktop Image For Turkish"
        placeholder="Upload the image."
        accept="image/*"
        multiline={false}
        validate={required()}
        onChange={async (file) => {
          setBuffer({ ...buffer, tu: file });
          //   fileToArrayBuffer(file).then((data) => {
          //     setBuffer({ ...buffer, tu: data });
          //   });
        }}
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <ImageInput
        source="mobile.en"
        label="Add Mobile Image For English"
        placeholder="Upload the image for mobile english."
        accept="image/*"
        multiline={false}
        validate={required()}
        onChange={async (file) => {
          setMobBuffer({ ...mobBuffer, en: file });
          //   fileToArrayBuffer(file).then((data) => {
          //     setMobBuffer({ ...mobBuffer, en: data });
          //   });
        }}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="mobile.tu"
        label="Add Mobile Image For English"
        placeholder="Upload the image for mobile turkish."
        accept="image/*"
        multiline={false}
        validate={required()}
        onChange={async (file) => {
          setMobBuffer({ ...mobBuffer, tu: file });
          //   fileToArrayBuffer(file).then((data) => {
          //     setMobBuffer({ ...mobBuffer, tu: data });
          //   });
        }}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="url" validate={required()} />
    </SimpleForm>
  );
};

export default ProfileInfoCreate;
