import * as React from "react";
import {
    Show, useRedirect, useNotify, SimpleShowLayout, TextField, DateField, ImageField, BooleanField,
    TopToolbar, DeleteWithConfirmButton, EditButton
} from 'react-admin';
import BackButton from "../backButton";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    imgContainer: {
        '& img': {
            height: '150px',
            width: '150px',
            objectFit: 'contain',
        }
    }
})

const PopularCollectionShow = (props) => {

    const notify = useNotify();
    const redirect = useRedirect();

    const classes = useStyles();

    const CustomShowActions = ({ basePath, data, resource }) => (
        <TopToolbar>
            <BackButton color='primary' style={{ marginLeft: '5rem' }} />
            {/* <EditButton basePath={basePath} record={data} /> */}
            {data ? <DeleteWithConfirmButton undoable={false} onSuccess={onSuccess} onFailure={onFailure} basePath={basePath} record={data} resource={resource} /> : ''}
        </TopToolbar>
    )

    const onSuccess = () => {
        notify(`Popular deleted successfully`, `success`)
        redirect('/popular-collection')
    }

    const onFailure = (error) => {
        console.log(error)
        notify(`Popular can not be delete`, `warning`)
        redirect('/popular-collection')
    }

    return (
        <Show {...props} actions={<CustomShowActions />}>
            <SimpleShowLayout>
                <TextField source="id" sortable={false} />
                <TextField source="collectionId.name" sortable={false} label="Name" />
                <ImageField source="collectionId.logo" sortable={false} label="Image" className={classes.imgContainer} />
                <TextField source="ranking" sortable={false} />
                <DateField source="createdAt" sortable={false} />
            </SimpleShowLayout>
        </Show>
    )
}

export default PopularCollectionShow;