import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import { services } from "../services";


const authProvider = (type, params) => {

    if (type === AUTH_LOGIN) {
        const request = services.post('/admin/login', params, { headers: { "content-type": "application/json", } });
        return request
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                localStorage.setItem('token', response.data.data.token);
            })
            .catch((e) => {
                if (e.response) {
                    throw new Error(e.response.data.message)
                } else {
                    throw new Error('Network Error')
                }
            });
    }

    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token');
        return Promise.resolve();
    }

    if (type === AUTH_ERROR) {
        const status = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    }

    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }

    return Promise.resolve();
}

export default authProvider;