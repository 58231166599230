import { useState, useEffect } from 'react';
import {
    SimpleForm,
    TextInput,
    required,
    useRedirect,
    useNotify,
    TopToolbar,
    Toolbar,
    SaveButton,
    SimpleShowLayout,
    SelectArrayInput,
} from 'react-admin';
import dataProvider from '../../authentication/dataProvider';
import { actions } from '../../actions';
import { services } from '../../services';
import Loader from '../loading';
import BackButton from '../backButton';

const NFTEdit = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [loading, setLoading] = useState(false);
    const [callAPI, setCallAPI] = useState(false);
    const [categories, setCategory] = useState([]);
    const [params, setParams] = useState(null);
    const [nft, setNFT] = useState(null);

    useEffect(() => {
        async function getCategory() {
            const categories = await actions.getCategories();
            setCategory(categories.data);
        }
        getCategory();

        async function fetchNFT() {
            const response = await dataProvider.getOne('nft', { id: props.id });
            setNFT(response.data);
        }
        fetchNFT();

        async function updateNFT() {
            const request = services.put(`nft/updateNft/${nft.id}`, params);
            request
                .then((response) => {
                    setLoading(false); // stop loading.
                    if (response.status === 200) {
                        notify(response.data.message, `success`);
                        redirect('/nft');
                    }
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                })
                .catch((e) => {
                    setLoading(false); // stop loading.
                    if (e.response) {
                        if (e.response.status === 401 || e.response.status === 403) {
                            notify(`Unauthorized Request`, `error`);
                            localStorage.removeItem('token');
                            redirect('/nft');
                        }
                        // other error code (404, 500, etc): no need to log out
                    } else {
                        notify(`Bad request`, `error`);
                        redirect('/nft');
                    }
                });
        }

        if (callAPI) {
            updateNFT();
        }
    }, [callAPI]);

    const onSuccess = async (event) => {
        setLoading(true); // start loader
        setParams({ id: nft.id, category: event.category });
        setCallAPI(true);
    };

    const NFTActionToolbar = () => (
        <TopToolbar>
            <BackButton color='primary' style={{ marginLeft: '5rem' }} />
        </TopToolbar>
    );

    const NFTEditToolbar = (props) => (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );

    return loading ? (
        <Loader />
    ) : nft ? (
        <>
            <SimpleForm save={onSuccess} toolbar={<NFTEditToolbar />}>
                <NFTActionToolbar />

                <TextInput disabled label='Id' source='id' initialValue={nft.id} />
                <TextInput
                    disabled
                    label='Name'
                    source='name'
                    initialValue={nft.title}
                />
                <TextInput
                    disabled
                    label='Description'
                    source='description'
                    initialValue={nft.description}
                />
                <SimpleShowLayout>
                    <SelectArrayInput
                        source='category'
                        choices={categories}
                        defaultValue={nft.category
                            ?.filter((category) => category)
                            .map((category) => category.id)}
                        optionValue='id'
                        optionText={(category) => `${category.categoryName.en}`}
                        validate={required()}
                    />
                </SimpleShowLayout>
            </SimpleForm>
        </>
    ) : (
        ''
    );
};

export default NFTEdit;
