import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  BooleanField,
  Pagination,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import DisablePaymentToken from "./paymentToken.disable";

const useStyles = makeStyles({
  imgContainer: {
    "& img": {
      height: "60px",
      width: "60px",
      objectFit: "contain",
      // borderRadius: '50%',
      margin: "10px",
    },
  },
});

const NFTShowButton = ({ record }) => (
  <ShowButton basePath="/nft" label="Show" record={record} />
);

const NFTPagination = (props) => (
  <Pagination rowsPerPageOptions={[]} {...props} />
);

const PaymentTokenList = (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      bulkActionButtons={false}
      title="List of NFTs"
      perPage={20}
      pagination={<NFTPagination />}
    >
      <Datagrid style={{ tableLayout: "fixed" }}>
        <TextField
          source="id"
          sortable={false}
          style={{ wordBreak: "break-all" }}
        />
        {/* <CustomRowLayout /> */}
        <TextField
          source="tokenAddress"
          sortable={false}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: "2",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          }}
        />
        <TextField
          source="symbol"
          sortable={false}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: "2",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          }}
        />
        {/* <BooleanField source="isActive" sortable={false} />
        <NFTShowButton source="View" /> */}
        <DisablePaymentToken source="Action" />
      </Datagrid>
    </List>
  );
};

export default PaymentTokenList;
