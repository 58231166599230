import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { List, Datagrid, TextField, DateField, BooleanField, ShowButton, Pagination, ImageField } from 'react-admin';


const categorieRowClick = (id, basePath, record) => record.editable ? 'edit' : 'show';
const CategoryShowButton = ({ record }) => (
    <ShowButton basePath="/categories" label="Show" record={record} />
)
const CategoryPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const useStyles = makeStyles({
    imgContainer: {
        '& img': {
            height: '50px',
            width: '50px',
            objectFit: 'contain',
        }
    }
})

const CategoriesList = (props) => {

    const classes = useStyles();

    return (
        <List {...props} bulkActionButtons={false}
            title="List of Categories"
            perPage={20}
            pagination={<CategoryPagination />}
        >
            <Datagrid rowClick={categorieRowClick}>
                <TextField source="id" sortable={false} />
                <ImageField source="image" sortable={false} label="Image" className={classes.imgContainer} />
                <TextField source="categoryName.en" sortable={false} label="English Version Banner" />
                <TextField source="categoryName.tu" sortable={false} label="Turkish Version Banner" />
                <DateField source="createdAt" sortable={false} />
                <BooleanField source="isActive" sortable={false} />
                <CategoryShowButton source="View" />
            </Datagrid>
        </List>
    )
}

export default CategoriesList;