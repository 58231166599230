import { useState, useEffect } from "react";
import fileToArrayBuffer from "file-to-array-buffer";
import {
  SimpleForm,
  TextInput,
  useRedirect,
  useNotify,
  SelectInput,
  NumberInput,
  required,
  ImageInput,
  ImageField,
  SelectArrayInput,
  SimpleShowLayout,
  FileInput,
  FileField,
} from "react-admin";
import { actions } from "../../actions";
import { services } from "../../services";
import Loader from "../loading";
import { web3 } from "../../web3";
import { compressImage } from "../../helper/function";
import ipfs from "../../config/ipfs";
import tokenAbi from "../../contractData/abis/token.json";

const PaymentTokenCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [token, setToken] = useState(null);
  const [callAPI, setCallAPI] = useState(false);
  const [web3Data, setWeb3Data] = useState(null);

  useEffect(() => {
    async function getWeb3Data() {
      const web3Data = await services.getWeb3();
      setWeb3Data(web3Data);
    }
    getWeb3Data();
  }, []);

  //   useEffect(() => {
  //     if (token) {
  //       mintingNFT();
  //       setToken(null);
  //     }
  //   }, [token]);

  useEffect(() => {
    if (callAPI) {
      createNFT();
      setCallAPI(false);
    }
  }, [callAPI]);

  const addingPaymentToken = async () => {
    const nftContractInstance = await actions.getNFTContractInstance();
    console.log(data);
    let hash = "";
    await nftContractInstance.methods
      .addPaymentTokens(data.tokenAddress)
      .send({ from: web3Data.accounts[0] })
      .on("transactionHash", (_hash) => {
        console.log("transaction hash : ", hash);
        // setTransactionId(hash)
        hash = _hash;
        console.log(hash);
      })
      .on("receipt", (receipt) => {
        console.log("on receipt ", receipt.transactionHash);
        onReciept(receipt);
        addToBackend(receipt.transactionHash);
      })
      .on("error", (error) => {
        console.log("on error ", error); // error.code===4001 user reject the metamask transaction
        onTransactionError(error);
      });
  };
  const addToBackend = (txnHash) => {
    const params = {
      tokenAddress: data.tokenAddress,
      symbol: data.symbol,
      txnHash: txnHash,
    };
    const request = services.post(`/nft/addTokenHash`, params);
    request
      .then((response) => {
        setLoading(false); // stop loading.
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        if (response.status === 200) {
          notify(response.data.message, `success`);
        }
      })
      .catch((e) => {
        setLoading(false); // stop loading.
        if (e.response) {
          if (e.response.status === 401 || e.response.status === 403) {
            notify(`Unauthorized Request`, `error`);
            localStorage.removeItem("token");
            redirect("/banners");
          }
          // other error code (404, 500, etc): no need to log out
        } else {
          notify(`Bad request`, `error`);
          redirect("/banners");
        }
      });
  };

  const onReciept = (receipt) => {
    setLoading(false); // stop loading
    notify("Payment token added to the backend successfully", `success`);
  };

  const onTransactionError = (error) => {
    setLoading(false); // stop loading
    notify("New payment token addition failed", `error`);
  };

  const createNFT = async () => {
    const request = services.post(`/nft/addNft`, data);
    request
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          setLoading(false); // stop loading.
          throw new Error(response.statusText);
        }
        if (response.status === 200) {
          console.log(response.data);
        }
      })
      .catch((e) => {
        setLoading(false); // stop loading.
        if (e.response) {
          if (e.response.status === 401 || e.response.status === 403) {
            notify(`Unauthorized Request`, `error`);
            localStorage.removeItem("token");
            redirect("/nft");
          }
          // other error code (404, 500, etc): no need to log out
        } else {
          notify(`Bad request`, `error`);
          redirect("/nft");
        }
      });
  };

  const getAddress = (id, creators) => {
    const address = creators
      .filter((data) => data.id === id)
      .map((user) => user.walletAddress)[0];
    return address;
  };
  const getTokenDetails = async (tokenAddress) => {
    const isValid = web3.utils.isAddress(tokenAddress);
    console.log(isValid);
    if (isValid) {
      const tokenContractInstance = await services.getContractInstance(
        tokenAbi,
        tokenAddress
      );
      const decimals = await tokenContractInstance.methods.decimals().call();
      const symbol = await tokenContractInstance.methods.symbol().call();
      setData({ ...data, decimals, symbol, tokenAddress });
      console.log(decimals, symbol);
    }
  };
  console.log(data);
  const onSubmit = () => {};
  return loading ? (
    <Loader />
  ) : (
    <SimpleForm save={addingPaymentToken}>
      <h4>Add Payment Token</h4>
      <SimpleShowLayout>
        <TextInput
          source="tokenAddress"
          validate={required()}
          onChange={(event) => {
            getTokenDetails(event.target.value);
          }}
        />
        <TextInput
          source="decimal"
          validate={required()}
          defaultValue={data.decimals}
          onChange={(event) =>
            setData({ ...data, description: event.target.value })
          }
        />
        <TextInput
          source="symbol"
          //   value={data.symbol}
          defaultValue={data.symbol}
          validate={required()}
          //   onChange={(event) =>
          //     setData({ ...data, description: event.target.value })
          //   }
        />
      </SimpleShowLayout>
    </SimpleForm>
  );
};

export default PaymentTokenCreate;
