import React, { Fragment } from "react";
import {
  Button,
  SimpleShowLayout,
  SimpleForm,
  NumberInput,
  useRedirect,
  useNotify,
  TextField,
  ImageField,
} from "react-admin";
import { useState, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { services } from "../../services";
import Loader from "../loading";
import { getFileType } from "../../helper/function";
import VideoCover from "../../video-cover.jpg";

import { makeStyles } from "@material-ui/core/styles";
import { actions } from "../../actions";

const useStyles = makeStyles({
  imgContainer: {
    "& img": {
      height: "150px",
      width: "300px",
      objectFit: "contain",
    },
  },
});

const CustomRowLayout = (record) => {
  const classes = useStyles();
  const [ext, setExt] = useState(false);

  useEffect(() => {
    function getExtenstion() {
      if (!record.image.format) {
        let ext = getFileType(record.image.compressed);
        ext.then(function (result) {
          setExt(result);
        });
      } else {
        setExt(record.image.format);
      }
    }
    getExtenstion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  if (ext && ext === `image`)
    return (
      <ImageField
        source="image.original"
        sortable={false}
        label="Image"
        className={classes.imgContainer}
      />
    );
  if (ext && ext === `audio`)
    return (
      <ImageField
        source="image.original"
        sortable={false}
        label="Image"
        className={classes.imgContainer}
      />
    );
  if (ext && ext === `video`)
    return (
      <ReactPlayer
        controls={true}
        url={record.image.original}
        playIcon={<></>}
        width="450px"
        height="250px"
        light={VideoCover}
      />
    );
  else return `loading..`;
};

const DisablePaymentToken = (props) => {
  const classes = useStyles();
  //   const { tokenAddress, symbol } = record;.
  console.log("this", props);

  const notify = useNotify();
  const redirect = useRedirect();
  const [web3Data, setWeb3Data] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [params, setParams] = useState(null);
  const [loading, setLoading] = useState(false);
  const [callAPI, setCallAPI] = useState(false);

  useEffect(() => {
    async function getWeb3Data() {
      const web3Data = await services.getWeb3();
      setWeb3Data(web3Data);
    }
    getWeb3Data();
  }, []);
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    if (callAPI) {
      addPopularNFT();
    }
  }, [callAPI]);

  const addPopularNFT = async () => {
    const request = services.post(`/admin/popular/add`, params);
    request
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          setLoading(false); // stop loading.
          setShowDialog(false);
          throw new Error(response.statusText);
        }
        if (response.status === 200) {
          notify(response.data.message, `success`);
          setLoading(false); // stop loading.
          setShowDialog(false);
        }
      })
      .catch((e) => {
        setLoading(false); // stop loading.
        setShowDialog(false);
        if (e.response) {
          if (e.response.status === 401 || e.response.status === 403) {
            notify(`Unauthorized Request`, `error`);
            localStorage.removeItem("token");
            redirect("/nft");
          }
          // other error code (404, 500, etc): no need to log out
        } else {
          notify(`Bad request`, `error`);
          redirect("/nft");
        }
      });
  };

  const disableToken = async () => {
    const nftContractInstance = await actions.getNFTContractInstance();
    await nftContractInstance.methods
      .addPaymentTokens(props)
      .send({ from: props.accounts?.[0] })
      .on("transactionHash", (hash) => {
        console.log("transaction hash : ", hash);
        // setTransactionId(hash)
        console.log(hash);
      })
      .on("receipt", (receipt) => {
        console.log("on receipt ", receipt);
        onReciept(receipt);
      })
      .on("error", (error) => {
        console.log("on error ", error); // error.code===4001 user reject the metamask transaction
        onTransactionError(error);
      });
  };
  const onReciept = (receipt) => {
    setLoading(false); // stop loading
    notify("NFT added successfully", `success`);
    redirect("/nft");
  };

  const onTransactionError = (error) => {
    setLoading(false); // stop loading
    notify("New payment token addition failed", `error`);
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <Fragment>
        <Button onClick={handleClick} label="Disable"></Button>

        <Dialog
          fullWidth
          open={showDialog}
          onClose={handleCloseClick}
          aria-label="popular NFT"
        >
          <DialogTitle>
            Are you sure you want to disable this token as payment option ?
          </DialogTitle>
          <DialogContent>
            <SimpleShowLayout>
              <TextField source="tokenAddress" sortable={false} />
              <TextField source="symbol" sortable={false} />
              {/* <CustomRowLayout {...record} /> */}
            </SimpleShowLayout>

            {/* <SimpleForm
              save={onSave}
              // form="post-quick-create"
              // resource="posts"
              // onSubmit={this.handleSubmit}
              // toolbar={null}
            >
              <SimpleShowLayout>
                <NumberInput source="ranking" required initialValue={0} />
              </SimpleShowLayout>
            </SimpleForm> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => disableToken()} label="Disable"></Button>{" "}
            <Button label="Cancel" onClick={handleCloseClick}></Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default DisablePaymentToken;
