import React, { useState, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import {
  Show,
  useRedirect,
  useNotify,
  SimpleShowLayout,
  TextField,
  DateField,
  ImageField,
  BooleanField,
  TopToolbar,
  DeleteWithConfirmButton,
  EditButton,
} from "react-admin";
import BackButton from "../backButton";
import { makeStyles } from "@material-ui/core/styles";
import { getFileType } from "../../helper/function";
import VideoCover from "../../video-cover.jpg";

const useStyles = makeStyles({
  imgContainer: {
    "& img": {
      height: "150px",
      width: "150px",
      objectFit: "contain",
    },
  },
});

const CustomRowLayout = ({ record }) => {
  const classes = useStyles();
  const [ext, setExt] = useState(false);

  useEffect(() => {
    function getExtenstion() {
      if (!record.nftId.image.format) {
        let ext = getFileType(record.nftId.image.compressed);
        ext.then(function (result) {
          setExt(result);
        });
      } else {
        setExt(record?.nftId?.image?.format);
      }
    }
    getExtenstion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  if (ext && ext === `image`)
    return (
      <ImageField
        source="nftId.image.original"
        sortable={false}
        label="Image"
        className={classes.imgContainer}
      />
    );
  if (ext && ext === `audio`)
    return <ReactAudioPlayer src={record.nftId.image.compressed} controls />;
  if (ext && ext === `video`)
    return (
      <ReactPlayer
        controls={true}
        url={record.nftId.image.original}
        playIcon={<></>}
        width="450px"
        height="250px"
        light={VideoCover}
      />
    );
  else return `loading..`;
};

const PopularNFTShow = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const classes = useStyles();

  const CustomShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
      <BackButton color="primary" style={{ marginLeft: "5rem" }} />
      {/* <EditButton basePath={basePath} record={data} /> */}
      {data ? (
        <DeleteWithConfirmButton
          undoable={false}
          onSuccess={onSuccess}
          onFailure={onFailure}
          basePath={basePath}
          record={data}
          resource={resource}
        />
      ) : (
        ""
      )}
    </TopToolbar>
  );

  const onSuccess = () => {
    notify(`Popular deleted successfully`, `success`);
    redirect("/popular-nft");
  };

  const onFailure = (error) => {
    console.log(error);
    notify(`Popular can not be delete`, `warning`);
    redirect("/popular-nft");
  };

  return (
    <Show {...props} actions={<CustomShowActions />}>
      <SimpleShowLayout>
        <TextField source="id" sortable={false} />
        <TextField source="nftId.title" sortable={false} label="NFT Title" />
        {/* <ImageField source="nftId.image.compressed" sortable={false} label="NFT Image" className={classes.imgContainer} /> */}
        <CustomRowLayout />
        <TextField source="ranking" sortable={false} />
        <DateField source="createdAt" sortable={false} />
      </SimpleShowLayout>
    </Show>
  );
};

export default PopularNFTShow;
