import * as React from "react";
import { useState, useEffect } from "react";
import fileToArrayBuffer from "file-to-array-buffer";
import {
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
  useRedirect,
  useNotify,
} from "react-admin";
import { services } from "../../services";
import ipfs from "../../config/ipfs";
import Loader from "../loading";
import { storeNFT } from "../../config/storage";

const CategoryCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  const [params, setParams] = useState(null);
  const [buffer, setBuffer] = useState(null);

  useEffect(() => {
    async function addCategory() {
      const request = services.post(`/category/add`, params);
      request
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            setLoading(false); // stop loading.
            throw new Error(response.statusText);
          }
          if (response.status === 200) {
            console.log(response.data);
            setLoading(false); // stop loading.
            notify(response.data.message, `success`);
            redirect("/categories");
          }
        })
        .catch((e) => {
          setLoading(false); // stop loading.
          if (e.response) {
            if (e.response.status === 401 || e.response.status === 403) {
              notify(`Unauthorized Request`, `error`);
              localStorage.removeItem("token");
              redirect("/categories");
            }
            // other error code (404, 500, etc): no need to log out
          } else {
            notify(`Bad request`, `error`);
            redirect("/categories");
          }
        });
    }

    if (callAPI) {
      addCategory();
    }
  }, [callAPI]);

  const onSuccess = async (params) => {
    if (params.image) {
      /* `category` is the folder name inside these category image is storing */
      // let hash = await ipfs.add(buffer, { // get buffer IPFS hash
      //     pin: true, progress: (bytes) => {
      //         // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (params.banner.en.rawFile.size)))
      //     }
      // })
      let hash = await storeNFT(buffer, params.name.en, "desc");
      setParams({ image: hash.path, name: params.name });
    } else {
      setParams({ name: params.name });
    }
    setCallAPI(true);
  };

  return loading ? (
    <Loader />
  ) : (
    <SimpleForm save={onSuccess}>
      <ImageInput
        source="image"
        label="Category Image"
        placeholder="Upload the category image."
        accept="image/*"
        multiline={false}
        onChange={(file) => {
          setBuffer(file);
        }}
      >
        <ImageField source="src" title="category image" />
      </ImageInput>
      <TextInput source="name.en" label="English Version Banner" />
      <TextInput source="name.tu" label="Turkish Version Banner" />
    </SimpleForm>
  );
};

export default CategoryCreate;
