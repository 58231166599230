import { useState, useEffect } from "react";
import fileToArrayBuffer from "file-to-array-buffer";
import {
  SimpleForm,
  TextInput,
  required,
  ImageInput,
  ImageField,
  useRedirect,
  useNotify,
  Toolbar,
  SaveButton,
  SimpleShowLayout,
  SelectArrayInput,
} from "react-admin";
import dataProvider from "../../authentication/dataProvider";
import { actions } from "../../actions";
import { services } from "../../services";
import ipfs from "../../config/ipfs";
import Loader from "../loading";
import { storeNFT } from "../../config/storage";

const CollectionEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  const [categories, setCategory] = useState([]);
  const [buffer, setBuffer] = useState(null);
  const [params, setParams] = useState({
    id: null,
    logo: null,
    name: null,
    description: null,
  });

  useEffect(() => {
    async function fetchCollection() {
      const response = await dataProvider.getOne("collections", {
        id: props.id,
      });
      setParams({
        id: response.data.id,
        logo: response.data.logo || null,
        name: response.data.name,
        description: response.data.description,
        category: response.data.category,
      });
    }
    fetchCollection();

    async function getCategory() {
      const categories = await actions.getCategories();
      setCategory(categories.data);
    }
    getCategory();

    async function updateCollection() {
      const request = services.put(`nft/updateCollection/${params.id}`, params);
      request
        .then((response) => {
          setLoading(false); // stop loading.
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          if (response.status === 200) {
            notify(response.data.message, `success`);
            redirect("/collections");
          }
        })
        .catch((e) => {
          setLoading(false); // stop loading.
          if (e.response) {
            if (e.response.status === 401 || e.response.status === 403) {
              notify(`Unauthorized Request`, `error`);
              localStorage.removeItem("token");
              redirect("/collections");
            }
            // other error code (404, 500, etc): no need to log out
          } else {
            notify(`Bad request`, `error`);
            redirect("/collections");
          }
        });
    }

    if (callAPI) {
      updateCollection();
    }
  }, [callAPI]);

  const CollectionEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  const onSuccess = async (event) => {
    setLoading(true); // start loader
    let hash = params.logo;
    if (buffer) {
      let ipfs_hash = await storeNFT(buffer, buffer.name, "desc");
      hash = ipfs_hash.path;
    }
    setParams({
      ...params,
      name: event.name,
      logo: hash,
      description: event.description,
      category: event.category,
    });
    setCallAPI(true);
  };

  const PreviewImage = ({ record, source }) => {
    if (typeof record == "string") {
      record = {
        [source]: record,
      };
    }
    return <ImageField record={record} source={source} />;
  };

  return loading ? (
    <Loader />
  ) : (
    <SimpleForm toolbar={<CollectionEditToolbar />} save={onSuccess}>
      <TextInput disabled label="Id" source="id" initialValue={params.id} />
      <ImageInput
        initialValue={params.logo}
        source="logo"
        label="Collection Logo"
        placeholder="Upload the logo."
        accept="image/*"
        multiline={false}
        validate={required()}
        onChange={async (file) => {
          setBuffer(file);
          // fileToArrayBuffer(file).then((buffer) => {
          //   setBuffer(buffer)
          // })
        }}
      >
        <PreviewImage source="logo" />
      </ImageInput>
      <TextInput
        source="name"
        validate={required()}
        initialValue={params.name}
      />
      <TextInput
        source="description"
        validate={required()}
        initialValue={params.description}
      />
      <SimpleShowLayout>
        <SelectArrayInput
          source="category"
          choices={categories}
          defaultValue={params.category
            ?.filter((category) => category)
            .map((category) => category.id)}
          optionValue="id"
          optionText={(category) => `${category.categoryName.en}`}
          validate={required()}
        />
      </SimpleShowLayout>
    </SimpleForm>
  );
};

export default CollectionEdit;
