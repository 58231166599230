import { useState, useEffect } from "react";
import {
  SimpleForm,
  TextInput,
  required,
  ImageInput,
  ImageField,
  useRedirect,
  useNotify,
  BooleanInput,
  DeleteButton,
  TopToolbar,
  Toolbar,
  SaveButton,
} from "react-admin";
import fileToArrayBuffer from "file-to-array-buffer";
import dataProvider from "../../authentication/dataProvider";
import { services } from "../../services";
import Loader from "../loading";
import ipfs from "../../config/ipfs";
import BackButton from "../backButton";
import { storeNFT } from "../../config/storage";

const HallFrameInfoEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  const [buffer, setBuffer] = useState({ en: null, tu: null });
  const [mobBuffer, setMobBuffer] = useState({ en: null, tu: null });
  const [params, setParams] = useState({
    id: null,
    url: null,
    status: null,
    button_text: null,
    button_url: null,
    banner: null,
    mobile: null,
  });

  useEffect(() => {
    async function fetchInfo() {
      const response = await dataProvider.getOne("hall-frame-info", {
        id: props.id,
      });
      setParams({
        id: response.data.id,
        url: response.data.url,
        status: response.data.isActive,
        button_text: response.data.button_text || null,
        button_url: response.data.button_url || null,
        banner: { en: response.data.banner.en, tu: response.data.banner.tu },
        mobile: { en: response.data.mobile.en, tu: response.data.mobile.tu },
      });
    }
    fetchInfo();

    async function updateInfo() {
      const request = services.put(
        `admin/hall-frame-info/update/${params.id}`,
        params
      );
      request
        .then((response) => {
          setLoading(false); // stop loading.
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          if (response.status === 200) {
            notify(response.data.message, `success`);
            redirect("/hall-frame-info");
          }
        })
        .catch((e) => {
          setLoading(false); // stop loading.
          if (e.response) {
            if (e.response.status === 401 || e.response.status === 403) {
              notify(`Unauthorized Request`, `error`);
              localStorage.removeItem("token");
              redirect("/hall-frame-info");
            }
            // other error code (404, 500, etc): no need to log out
          } else {
            notify(`Bad request`, `error`);
            redirect("/hall-frame-info");
          }
        });
    }

    if (callAPI) {
      updateInfo();
    }
  }, [callAPI]);

  const onSuccess = async (event) => {
    setLoading(true); // start loader
    let { banner, mobile } = event;
    let banner_ = {
      // en: params.banner.en.substring(params.banner.en.lastIndexOf("/") + 1)
      en: params.banner.en,
      tu: params.banner.tu,
    };
    let mobile_ = {
      en: params.mobile.en,
      tu: params.mobile.tu,
    };

    if (buffer.en) {
      banner_.en = await storeNFT(buffer?.en, buffer?.en?.name, "desc");
      banner_.en = banner_.en.path;
    }

    if (buffer.tu) {
      banner_.tu = await storeNFT(buffer?.tu, buffer?.tu?.name, "desc");
      banner_.tu = banner_.tu.path;
    }

    if (mobBuffer.en) {
      mobile_.en = await storeNFT(mobBuffer?.en, mobBuffer?.en?.name, "desc");
      mobile_.en = mobile_.en.path;
    }

    if (mobBuffer.tu) {
      mobile_.tu = await storeNFT(mobBuffer?.tu, mobBuffer?.tu?.name, "desc");
      mobile_.tu = mobile_.tu.path;
    }
    setParams({
      ...params,
      url: event.url,
      status: event.status,
      button_url: event.button_url,
      button_text: event.button_text,
      banner: banner_,
      mobile: mobile_,
    });
    setCallAPI(true);
  };

  const PreviewImage = ({ record, source }) => {
    if (typeof record == "string") {
      record = {
        [source]: record,
      };
    }
    return <ImageField record={record} source={source} />;
  };

  const HallFrameInfoActionToolbar = () => (
    <TopToolbar>
      <BackButton color="primary" style={{ marginLeft: "5rem" }} />
    </TopToolbar>
  );

  const HallFrameInfoEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton />
    </Toolbar>
  );

  return loading ? (
    <Loader />
  ) : params.id ? (
    <>
      <SimpleForm save={onSuccess} toolbar={<HallFrameInfoEditToolbar />}>
        <HallFrameInfoActionToolbar />
        <TextInput disabled label="Id" source="id" initialValue={params.id} />
        <ImageInput
          initialValue={params.banner.en}
          source="banner.en"
          label="Image for English"
          placeholder="Upload the image."
          accept="image/*"
          multiline={false}
          validate={required()}
          onChange={async (file) => {
            setBuffer({ ...buffer, en: file });
            // fileToArrayBuffer(file).then((data) => {
            //   setBuffer({ ...buffer, en: data });
            // });
          }}
        >
          <PreviewImage source="en" />
        </ImageInput>
        <ImageInput
          initialValue={params.banner.tu}
          source="banner.tu"
          label="Image for Turkish"
          placeholder="Upload the image."
          accept="image/*"
          multiline={false}
          validate={required()}
          onChange={async (file) => {
            setBuffer({ ...buffer, tu: file });
            // fileToArrayBuffer(file).then((data) => {
            //   setBuffer({ ...buffer, tu: data });
            // });
          }}
        >
          <PreviewImage source="src" />
        </ImageInput>
        <ImageInput
          initialValue={params.mobile.en}
          source="mobile.en"
          label="Mobile Image for English"
          placeholder="Upload the image."
          accept="image/*"
          multiline={false}
          validate={required()}
          onChange={async (file) => {
            setMobBuffer({ ...mobBuffer, en: file });
            // fileToArrayBuffer(file).then((data) => {
            //   setMobBuffer({ ...mobBuffer, en: data });
            // });
          }}
        >
          <PreviewImage source="en" />
        </ImageInput>
        <ImageInput
          initialValue={params.mobile.tu}
          source="mobile.tu"
          label="Mobile Image for Turkish"
          placeholder="Upload the image."
          accept="image/*"
          multiline={false}
          validate={required()}
          onChange={async (file) => {
            setMobBuffer({ ...mobBuffer, tu: file });
            // fileToArrayBuffer(file).then((data) => {
            //   setMobBuffer({ ...mobBuffer, tu: data });
            // });
          }}
        >
          <PreviewImage source="src" />
        </ImageInput>
        <TextInput
          initialValue={params.url}
          source="url"
          validate={required()}
        />
        {params.button_text && (
          <>
            <TextInput
              initialValue={params.button_text.en}
              validate={required()}
              source="button_text.en"
            />{" "}
            <br />
            <TextInput
              initialValue={params.button_text.tu}
              validate={required()}
              source="button_text.tu"
            />{" "}
            <br />
          </>
        )}
        {params.button_url && (
          <TextInput initialValue={params.button_url} source="button_url" />
        )}
        <BooleanInput source="status" initialValue={params.status} />
        <DeleteButton />
      </SimpleForm>
    </>
  ) : (
    ""
  );
};

export default HallFrameInfoEdit;
