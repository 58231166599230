import { useEffect, useState } from "react";
import fileToArrayBuffer from "file-to-array-buffer";
import {
  SimpleForm,
  TextInput,
  useRedirect,
  useNotify,
  ImageInput,
  ImageField,
  required,
} from "react-admin";
import { services } from "../../services";
import Loader from "../loading";
import ipfs from "../../config/ipfs";
import { storeNFT } from "../../config/storage";

const BannerCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  const [params, setParams] = useState({
    url: null,
    banner: null,
    mobile: null,
    buffer: null,
    mobBuffer: null,
  });

  useEffect(() => {
    async function addBanner() {
      const request = services.post(`/admin/banner/add`, params);
      request
        .then((response) => {
          setLoading(false); // stop loading.
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          if (response.status === 200) {
            notify(response.data.message, `success`);
            redirect("/banners");
          }
        })
        .catch((e) => {
          setLoading(false); // stop loading.
          if (e.response) {
            if (e.response.status === 401 || e.response.status === 403) {
              notify(`Unauthorized Request`, `error`);
              localStorage.removeItem("token");
              redirect("/banners");
            }
            // other error code (404, 500, etc): no need to log out
          } else {
            notify(`Bad request`, `error`);
            redirect("/banners");
          }
        });
    }

    if (callAPI) {
      addBanner();
    }
  }, [callAPI]);

  const onSuccess = async (event) => {
    setLoading(true); // start loader
    /* `banner` is the folder name inside these all images are storing */
    // let hash_en = await ipfs.add(params?.buffer.en, {
    //   // get buffer IPFS hash
    //   pin: true,
    //   progress: (bytes) => {
    //     // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (event.english.rawFile.size)))
    //   },
    // });
    let hash_en = await storeNFT(
      params?.buffer?.file,
      params?.buffer?.file?.name,
      "desc"
    );
    let hash_tu = await storeNFT(
      params?.buffer_tur?.file,
      params?.buffer_tur?.file?.name,
      "desc"
    );
    let hash_mob_en = await storeNFT(
      params?.mobBuffer?.file,
      params?.mobBuffer?.file?.name,
      "desc"
    );
    let hash_mob_tu = await storeNFT(
      params?.mobBuffer_tur?.file,
      params?.mobBuffer_tur?.file?.name,
      "desc"
    );
    setParams({
      url: event.url,
      banner: { en: hash_en.path, tu: hash_tu.path },
      mobile: { en: hash_mob_en.path, tu: hash_mob_tu.path },
    });
    setCallAPI(true);
  };
  console.log("Params:", params);
  return loading ? (
    <Loader />
  ) : (
    <SimpleForm save={onSuccess}>
      <ImageInput
        source="english"
        label="Add Image For English"
        placeholder="Upload the image."
        accept="image/*"
        multiline={false}
        validate={required()}
        onChange={async (file) => {
          // fileToArrayBuffer(file).then((buffer) => {
          setParams({ ...params, buffer: { file, en: "eng" } });
          // });
        }}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="turkish"
        label="Add Image For Turkish"
        placeholder="Upload the image."
        accept="image/*"
        multiline={false}
        validate={required()}
        onChange={async (file) => {
          setParams({ ...params, buffer_tur: { file, tu: "tur" } });
          // fileToArrayBuffer(file).then((buffer) => {
          //   setParams({ ...params, buffer: { ...params.buffer, tu: buffer } });
          // });
        }}
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <ImageInput
        source="mo_en"
        label="Add Image For Mobile English"
        placeholder="Upload  image for mobile."
        accept="image/*"
        multiline={false}
        validate={required()}
        onChange={async (file) => {
          setParams({ ...params, mobBuffer: { file, mob_en: "eng" } });
          // fileToArrayBuffer(file).then((buffer) => {
          //   setParams({
          //     ...params,
          //     mobBuffer: { ...params.mobBuffer, en: buffer },
          //   });
          // });
        }}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="mo_tu"
        label="Add Image For Mobile Turkish"
        placeholder="Upload image for mobile."
        accept="image/*"
        multiline={false}
        validate={required()}
        onChange={async (file) => {
          setParams({ ...params, mobBuffer_tur: { file, mob_tu: "tur" } });
          // fileToArrayBuffer(file).then((buffer) => {
          //   setParams({
          //     ...params,
          //     mobBuffer: { ...params.mobBuffer, tu: buffer },
          //   });
          // });
        }}
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <TextInput source="url" validate={required()} />
    </SimpleForm>
  );
};

export default BannerCreate;
